import Layout from '../../hocs/layouts/Layout';
import Header from '../../components/home/Header'
import BestSeller from '../../components/home/BestSeller';
import { Helmet } from 'react-helmet-async';

function Home(){
    return (
        <Layout>
            <Header />
            <BestSeller />
        </Layout>
    );
}


export default Home