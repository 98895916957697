import CardProduct from "../Cards/CardProduct";
import { connect } from 'react-redux';
import { useEffect } from 'react';
import {
  get_products_by_arrival,
  get_products_by_sold
} from '../../redux/actions/products';

function BestSeller({
  get_products_by_arrival,
  get_products_by_sold,
  products_arrival,
  products_sold,
}) {

  useEffect(() => {
    window.scrollTo(0, 0);
    get_products_by_arrival();
    get_products_by_sold();
  }, [get_products_by_arrival, get_products_by_sold]);

  return (
    <section className="text-center my-10 md:my-36">
      <h2 className="text-black text-3xl md:text-5xl font-semibold py-2 md:py-8">Últimos lanzamientos</h2>
      <div className="md:flex md:gap-10 justify-center mt-8">
        {products_arrival && products_arrival.map((product) => (
          <CardProduct
            key={product.id} // Agrega una key única cuando usas map
            id={product.id}
            src={product.get_photo}
            name={product.name}
            size={product.size}
            price={product.price}
            compare_price={product.compare_price}
          />
        ))}
      </div>
    </section>
  );
}

const mapStateToProps = state => ({
  products_arrival: state.Products.products_arrival,
  products_sold: state.Products.products_sold,
});

export default connect(mapStateToProps, {
  get_products_by_arrival,
  get_products_by_sold,
})(BestSeller);