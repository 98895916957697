import AnimatedRoutes from './Routes'
import { BrowserRouter } from 'react-router-dom'
import store from './store';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Best Dressed</title>
        <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
        <meta name="keyword" content="
          Ropa urbana argentina
          Moda urbana de calidad
          Marca de status en moda urbana
          Streetwear argentino
          Diseño de autor urbano
          Moda urbana auténtica
          Prendas de calidad argentina
          Elegancia en street fashion
          Marca de ropa desde Santa Fe
          Estilo urbano de Rosario
          Ropa exclusiva de Argentina
          Moda contemporánea argentina
          Streetwear de autor
          Tendencias urbanas en Argentina
          Ropa de calidad para la ciudad"></meta>
        <meta name="robots" content="all"></meta>
        <link rel="canonical" href=""></link>
        <meta name="author" content="Vainfax Solutions"></meta>
        <meta name="publisher" content="Vainfax Solutions"></meta>

        {/* Social Media Tags */}
        <meta property="og:title" content='Best Dressed' />
        <meta property="og:description" content='' />
        <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
        <meta property="og:image" content='' />

        <meta name="twitter:title" content='Best Dressed' />
        <meta
          name="twitter:description"
          content=''
          />
        </Helmet>
      <Provider store={store}>
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
    
  );
}

export default App;
