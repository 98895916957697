import { combineReducers } from "redux";
import Auth from './auth';
import Alert from "./alert";
import Collections from "./collections";
import Products from "./products";
import Cart from "./cart";

export default combineReducers({
    Auth,
    Alert,
    Collections,
    Products,
    Cart
})