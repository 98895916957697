import Muscu from '../../assets/img/tabla-de-talles-muscu.svg'
import Reme from '../../assets/img/tabla-de-talles-reme.svg'
import Buzo from '../../assets/img/tabla-de-talles-buzo.svg'


const GuiaDeTalles = ({ name }) => {

    if(name == '"F*ck The Dress Code"')
        return(
                <img 
                    src={Muscu}
                    width={200}
                    height={200}
                    className='pt-10 md:pt-6'
                />
        )
    if(name == '"Bst Drsd: Experience"')
        return(
                <img 
                    src={Reme}
                    width={220}
                    height={220}
                    className='pt-10 md:pt-6'
                />
        )
        if(name == 'Buzo Boxy Fit "Navy Blue"')
            return(
                    <img 
                        src={Buzo}
                        width={220}
                        height={220}
                        className='pt-10 md:pt-6'
                    />
            )

}


export default GuiaDeTalles