import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './containers/pages/Home'
import Products from './containers/pages/Products'
import Error404 from './containers/errors/Error404'
import Signup from './containers/auth/Signup'
import Login from './containers/auth/Login'
import ResetPassword from './containers/auth/ResetPassword'
import ResetPasswordConfirm from './containers/auth/ResetPasswordConfirm'
import ProductDetail from './containers/pages/ProductDetail'
import Payment from './containers/pages/Payment'
import PaymentPendingOrderId from './containers/pages/PaymentPendingOrderId'
import PaymentSuccessOrderId from './containers/pages/PaymentSuccessOrderId'
import PaymentFailureOrderId from './containers/pages/PaymentFailureOrderId'
import Orders from './containers/pages/Orders'
import WinterMaintenance from './containers/pages/WinterMaintenance'


function AnimatedRoutes () {
    
    const location = useLocation()

    return (
        <Routes location={location} key={location.pathname}>
            {/* Errors pages */}
            {/* <Route path="*" element={<WinterMaintenance />}/> */}
            <Route path="*" element={<Error404 />}/>

            {/* Authentication */}

            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />

            {/* Normal Display */}
            <Route exact path="/" element={<Home />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/products/:productId" element={<ProductDetail />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/payment/success/:orderId" element={<PaymentSuccessOrderId />} />
            <Route exact path="/payment/failure/:orderId" element={<PaymentFailureOrderId />} />
            <Route exact path="/payment/pending/:orderId" element={<PaymentPendingOrderId />} />
            <Route exact path="/orders" element={<Orders />} />
        </Routes>

    );
}

export default AnimatedRoutes;