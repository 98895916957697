
import { connect } from "react-redux"

import { motion } from 'framer-motion'

import { check_authenticated, load_user, refresh } from "../../redux/actions/auth"
import { useEffect } from "react"
import Navbar from "../../components/navigation/Navbar";
import Footer from "../../components/navigation/Footer";

function Layout(props){
    useEffect(() => {
        props.refresh();
        props.check_authenticated();
        props.load_user();
    }, []);

    return(
        <>
            <Navbar />
            <motion.div
            initial={{opacity:0, transition: {duration: 0.3}}}
            animate={{opacity:1}}
            exit={{opacity:1, transition: {duration: 0.3}}}
            >
                {props.children}
            </motion.div>
            <Footer />
        </>
    )
}

export default connect(null,{
    check_authenticated,
    load_user,
    refresh
}) (Layout);