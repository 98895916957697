

const TextWithShadow = ({ text, isTittle }) => {

  
  return (
    <div className="text-container">
      {text.split('').map((letter, index) => (
        <span key={index} className={`letter-shadow ${isTittle? 'border-b-4 border-brand-red': ''}`}>
          {letter}
        </span>
      ))}
    </div>
  );
};

export default TextWithShadow;