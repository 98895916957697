import Layout from '../../hocs/layouts/Layout';
import { useParams } from 'react-router';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async'

function PaymentPendingOrderId() {
  const { orderId } = useParams();
  const [order, setOrder] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
          }
        };

        // Reemplaza la URL con la dirección de tu API
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/order/get-order/${orderId}`, config);

        if (response.status === 200) {
          if (response.data.order.status === "processed"){
            // Accede a los datos de la respuesta
            setOrder(response.data.order)

            // eslint-disable-next-line
            fbq('track', 'Purchase', {
              value: 15.360,
              currency: 'ARS',
            })
          };
        };
        
      } catch (error) {
        // Maneja errores aquí, por ejemplo, imprime el mensaje de error
        console.error('Error al hacer la petición GET:', error.message);
      }
    };

    // Llama a la función asincrónica
    fetchData();
  }, [orderId]); // Asegúrate de incluir orderId en la lista de dependencias para que useEffect se ejecute cuando cambie

  const resumeOrder = () => {
    const items = order.order_items
    if(items &&
      items !== null &&
      items !== undefined &&
      items.length !== 0) {
        return(
          items.map((item, index) => (
            <div className='mb-6'>
              <div className='-m-3 flex gap-4 items-center rounded-lg p-2 transition duration-150 ease-in-out bg-brand-darkgray focus:outline-none focus-visible:ring focus-visible:ring-brand-red focus-visible:ring-opacity-50 pr-36 mx-80'>
                <img
                  src={item.product_photo}
                  width={50}
                  height={65}
                  className=""
                  />
                <p>{item.name}</p>
              </div>
            </div> 
          ))
        )
      }
  };

  return (
    <Layout>
      <Helmet>
        <title>Best Dressed | Pago Confirmado</title>
        <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
        <meta name="keyword" content="
                    Best dressed
                    Primera Cápsula
                    ya disponible
                    F*ck The Dress Code
                    Bst Drsd: Experience
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario"></meta>
        <meta name="robots" content="all"></meta>
        <link rel="canonical" href=""></link>
        <meta name="author" content="Vainfax Solutions"></meta>
        <meta name="publisher" content="Vainfax Solutions"></meta>

        {/* Social Media Tags */}
        <meta property="og:title" content='Best Dressed | Productosz' />
        <meta property="og:description" content='' />
        <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
        <meta property="og:image" content='' />

        <meta name="twitter:title" content='Best Dressed' />
        <meta
          name="twitter:description"
          content=''
            />
      </Helmet> 
      <section className='m-5 mt-10 lg:m-24'>
                <h2 className='flex md:hidden justify-center text-black text-2xl lg:text-4xl pt-24 font-semibold whitespace-nowrap'>La compra N.º <b>{orderId}</b>, ha</h2>
                <h2 className='flex md:hidden justify-center text-black text-2xl lg:text-4xl font-semibold mb-6 whitespace-nowrap'>sido confirmada.</h2>
                
                <h2 className='hidden md:flex justify-center text-black text-2xl lg:text-4xl pt-24 font-semibold mb-6 whitespace-nowrap'>La compra N.º <b>{orderId}</b>, ha sido confirmada.</h2>
                {resumeOrder()}
                <div id='div' className='lg:max-w-60 pt-6 pb-24'>
                    <p className='flex justify-center text-center text-lg pt-4 mx-2 md:mx-16'>Muchísimas gracias por elegirnos y confiar en nosotros. Te invitamos a compartir una foto subiendo una historia a Instagram y etiquetándonos en nuestra cuenta @bestdressedclo. ¡Agradecemos tu preferencia y esperamos que disfrutes al máximo de tus nuevas pilchas!</p>
                    <p className='flex justify-center text-center text-lg pt-6 mx-2 md:mx-16 font-semibold'>Recibirás el código de seguimiento del envío vía email.</p>
                </div>
            </section>
    </Layout>
  );
}

export default PaymentPendingOrderId;