import axios from 'axios';
import {
    GET_COLLECTION_SUCCESS,
    GET_COLLECTION_FAIL,
} from './types'


export const get_collection = () => async dispatch => {
    const config = {
        headers: {
            'Accept':'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/collection/list`, config);

        if (res.status === 200){
            dispatch({
                type: GET_COLLECTION_SUCCESS,
                payload: res.data,
            });
        } else {
            dispatch({
                type: GET_COLLECTION_FAIL,
            })
        }
    }
    
    catch(err) {
        dispatch({
            type: GET_COLLECTION_FAIL,
        })
    }
}