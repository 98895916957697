import { Link, Navigate } from "react-router-dom";
import Layout from "../../hocs/layouts/Layout";
import { useState, useEffect } from "react";
import { login } from "../../redux/actions/auth";
import isoTipo from '../../assets/img/isotipo-color.svg'

import { connect } from 'react-redux'
import { signup } from "../../redux/actions/auth";

function Register({
    signup,
    login,
}) {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [accountCreated, setAccountCreated] = useState(false);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
      })

    const { 
        first_name,
        last_name,
        email,
        password,
        re_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e =>{
        e.preventDefault();
        signup(first_name, last_name, email, password, re_password);
        setAccountCreated(true);
        window.scrollTo(0,0)
      }

    if (accountCreated) {
        return(
            <Navigate to="/" />
        )
    }


    return(
        <Layout >
            <div className="flex ">
            <div className="mx-auto justify-center max-w-xl border border-brand-darkgray rounded-2xl my-48 shadow-navbar">
            <img
                src={isoTipo}
                width={70}
                height={70}
                className="mt-6 flex mx-auto"
                />
            <h2 className="mt-4 flex justify-center text-gray-900 text-4xl font-semibold">Registrate</h2>

            <form onSubmit={e => onSubmit(e)} className="p-6" method="POST">
            <div class="relative z-0 w-full mb-6 group">
                <input type="email" name="email" value={email} onChange={e=>onChange(e)} id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Correo electronico</label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
                <input type="password" name="password" value={password} onChange={e=>onChange(e)} id="floating_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contraseña</label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
                <input type="password" name="re_password" value={re_password} onChange={e=>onChange(e)} id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_repeat_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirmar contraseña</label>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                    <input type="text" name="first_name" value={first_name} onChange={e=>onChange(e)} id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                    <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nombre</label>
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <input type="text" name="last_name" value={last_name} onChange={e=>onChange(e)} id="floating_last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                    <label for="floating_last_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Apellido</label>
                </div>
            </div>
            {/* <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                    <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                    <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Teléfono (123-456-7890)</label>
                </div>
                <div class="relative z-0 w-full mb-6 group">
                    <input type="text" name="floating_company" id="floating_company" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                    <label for="floating_company" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Dirección (calle y numero)</label>
                </div>
            </div> */}
            <button formMethod="POST" type="submit" class="text-brand-lightgray bg-brand-red hover:bg-darkred focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-full px-5 py-2.5 text-center dark:bg-black dark:hover:bg-brand-darkgray dark:focus:ring-brand-darkgray transition duration-200 ease-in-out">Registerme</button>
            <p className="pt-4">¿Ya tienes una cuenta? <Link to="/login" className="text-blue-600">Iniciar Sesion</Link></p>
            </form>
            </div>
            </div>

        </Layout>
    );
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {
    signup
}) (Register);