import Layout from '../../hocs/layouts/Layout';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { get_product } from '../../redux/actions/products';
import { 
    get_items,
    add_item,
    get_total,
    get_item_total } from '../../redux/actions/cart';
import { useEffect, useState } from 'react';
import ImageSelector from '../../components/imageSelector/ImageSelector';
import 'boxicons'
import { OneEightyRing } from "react-svg-spinners";
import GuiaDeTalles from '../../components/imageSelector/GuiaDeTalles';
import { Helmet } from 'react-helmet-async';


const ProductDetail = ({
    get_product,
    product,
    get_items,
    add_item,
    get_total,
    get_item_total
}) => {

    const [loading, setLoading] = useState(false)

    const addToCart = async () => {
        if (product && product !== null && product !== undefined && selectedSize > 0) {
            setLoading(true)
            await add_item(product, selectedTalle.toLowerCase(), "1");
            await get_items();
            await get_total();
            await get_item_total();
            setLoading(false)
        }
    }

    const params = useParams()
    const productId = params.productId

    /* const ArrayImages = [Foto, Foto2] */
    
    useEffect(() => {
        window.scrollTo(0, 0);
        get_product(productId);
    }, [])
    
    const [selectedTalle, setSelectedTalle] = useState();
    const [selectedSize, setSelectedSize] = useState();
    
    if (!product) {
        return <div>Cargando...</div>;
    }   
    
    const sizeOptions = [/* 'S', */ 'M', 'L', 'XL'];
    const sizeStock = [/* product.size_s,  */product.size_m, product.size_l, product.size_xl];
    
    
    const handleOptionClick = (talle, indice) => {
        setSelectedTalle(talle);
        setSelectedSize(sizeStock[indice])
    };
    const ArrayImages = [product.get_photo, product.get_photo_second, product.get_photo_thirth, product.get_photo_fourth]

    return (
        <Layout>
            <Helmet>
                <title>Best Dressed | {product.name}</title>
                <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
                <meta name="keyword" content="
                    Best dressed
                    Primera Cápsula
                    ya disponible
                    F*ck The Dress Code
                    Bst Drsd: Experience
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario"></meta>
                <meta name="robots" content="all"></meta>
                <link rel="canonical" href=""></link>
                <meta name="author" content="Vainfax Solutions"></meta>
                <meta name="publisher" content="Vainfax Solutions"></meta>

                {/* Social Media Tags */}
                <meta property="og:title" content='Best Dressed | Productosz' />
                <meta property="og:description" content='' />
                <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
                <meta property="og:image" content='' />

                <meta name="twitter:title" content='Best Dressed' />
                <meta
                    name="twitter:description"
                    content=''
                />
            </Helmet>
            <div className='mt-32 md:flex justify-center gap-10 mb-24 '>
                <div className=''>
                    <ImageSelector images={ArrayImages} />
                </div>
                <div className='sm:ml-5'>
                    <h2 className='text-3xl md:text-4xl whitespace-normal font-medium'>{product.name}</h2>
                    <p className='w-80 text-brand-darkgray'>{product.description}</p>
                    
                    {product.price !== product.compare_price?
                    <div className=''>
                        <div className='flex gap-2'>
                        {/* <span className='text-xl text-brand-red pt-2 font-bold'>20%OFF</span> */}<h3 className='text-lg text-black line-through pt-2'>${product.compare_price}</h3>
                        </div>
                        <h3 className='text-xl text-brand-red pb-4'>${product.price}</h3>
                    </div>
                    :
                    <h3 className='text-xl text-black pt-2 pb-4'>${product.price}</h3>
                    }
                    <h2>Selecciona tu talle:</h2>
                    <div className="space-x-2">
                        <div className="w-32 py-3 md:py-2 flex items-center">
                            <div className="flex">
                                {sizeOptions.map((talle, indice) => (
                                            
                                <button
                                    key={talle}
                                    onClick={() => handleOptionClick(talle, indice)}
                                    className={`p-2 ${selectedTalle === talle ? 'bg-brand-red hover:bg-brand-red text-brand-lightgray' : 'bg-brand-lightgray border border-brand-darkgray'} hover:bg-brand-darkgray hover:text-brand-lightgray w-10 mr-2 rounded`}
                                >
                                    {talle}
                                </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        {selectedTalle? selectedSize > 0 ? <div className='flex align-middle items-center'><i class='bx bxs-check-circle text-brand-darkgray text-2xl'></i><p>En stock</p></div> : <div className='flex align-middle items-center'><i class='bx bxs-x-circle text-brand-red text-2xl'></i><p>Agotado</p></div>: <span></span>}
                    </div>
                    <div className='flex gap-2'>
                        {loading? <button className="flex justify-center text-brand-lightgray text-sm  bg-black border border-black hover:bg-brand-darkgray hover:border-brand-darkgray transition duration-150 ease-in-out px-4 py-2 rounded-md">
                        <OneEightyRing 
                            width={30}
                            height={30}
                            color="#fff" 
                            />
                        </button>:
                        <button onClick={addToCart} className="flex justify-center text-brand-lightgray text-sm  bg-black border border-black hover:bg-brand-darkgray hover:border-brand-darkgray transition duration-150 ease-in-out px-4 py-2 rounded-md">
                        Agregar al Carrito
                        </button>}
                    </div>
                    <div className='flex justify-center md:justify-start'>
                        <GuiaDeTalles 
                            name={product.name}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    product: state.Products.product,
})

export default connect(mapStateToProps, {
    get_product,
    get_items,
    add_item,
    get_total,
    get_item_total
}) (ProductDetail);