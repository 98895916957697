import { Popover, Transition } from '@headlessui/react'
import 'boxicons'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import {
  get_items,
  get_total,
  get_item_total,
  remove_item
} from "../../redux/actions/cart"
import { useEffect } from 'react'
import TotalItems from './TotalItems'


function Cart({
  get_items,
  get_total,
  get_item_total,
  isAutheticated,
  items,
  amount,
  compare_amount,
  total_items,
  remove_item,
  
}) {
    useEffect(() => {
      get_items()
      get_total()
      get_item_total()
    }, [])

    const checkoutButton = () => {
      if (isAutheticated === false) {
        return(
          <Link
          to='/login'
          className='flow-root rounded-md px-2 py-2 transition duration-200 ease-in-out bg-black hover:bg-brand-darkgray focus:outline-none focus-visible:ring-opacity-50'
        >
          <span className="flex items-center justify-center">
            <span className="text-sm font-medium text-brand-lightgray">
              iniciar Sesion
            </span>
          </span>
        </Link>
        )
      } else if (total_items < 1) {
        return(
          <Link
          to='/products'
          className='flow-root rounded-md px-2 py-2 transition duration-200 ease-in-out bg-black hover:bg-brand-darkgray focus:outline-none focus-visible:ring-opacity-50'
        >
          <span className="flex items-center justify-center">
            <span className="text-sm font-medium text-brand-lightgray">
              Ir a la tienda
            </span>
          </span>
        </Link>
        )
      } else {
        return(
          <Link
          to="/payment"
          className="flow-root rounded-md px-2 py-2 transition duration-200 ease-in-out bg-black hover:bg-brand-darkgray focus:outline-none focus-visible:ring-opacity-50"
        >
          <span className="flex items-center justify-center">
            <span className="text-sm font-medium text-brand-lightgray">
              Finalizar compra
            </span>
          </span>
        </Link>
        )
      }
    }
    
    const removeItemHandler = async (productId, size_item) => {
      
      await remove_item(productId, size_item);
      get_items()
      get_total()
      get_item_total()
    };

    const mapItems = () => {
        if(items &&
        items !== null &&
        items !== undefined &&
        items.length !== 0) {
          return(
            items.map((item, index) => (
              <div className='flex justify-between'>
                <Link
                    key={item.product.id}
                    to={`/products/${item.product.id}`}
                    className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-brand-darkgray focus:outline-none focus-visible:ring focus-visible:ring-brand-red focus-visible:ring-opacity-50 pr-36"
                >
                    <img
                        src={item.product.get_photo}
                        width={50}
                        height={75}
                        className=""
                        />
                    <div className="ml-4">
                        <p className="text-sm font-medium text-gray-900">
                            {item.product.name} x ({item.count})
                        </p>
                        <div className="flex justify-between">
                            <p className="text-sm text-grat-500">
                            {item.size_item ? item.size_item.toUpperCase() : ""}
                            </p>
                            <p className="text-sm text-black -mr-24">
                                ${item.product.price}
                            </p>
                        </div>
                    </div>
                </Link>
                
                <button 
                    onMouseEnter={() => {
                      const x = document.getElementById(item.id);
                      if (x) {
                          x.classList.add("text-white");
                      }
                  }}
                    onMouseLeave={()=>{
                    const x = document.getElementById(item.id)
                    if (x) {
                      x.classList.remove("text-white")
                    }
    
                    }}
    
                    onClick={(e) => {
                      e.preventDefault();
                      removeItemHandler(item.product.id, item.size_item);
                  }}
    
                className="rounded-lg px-3 py-7 6 transition duration-150 ease-in-out hover:bg-brand-red -mr-4 justify-center">
                <i id={item.id} className='bx bx-x text-2xl' ></i>
                </button>
                </div>
              
            ))
          )
        } else if (total_items === 0) {
          return (
            <p className='text-brand-darkgray text-sm'>No hay items en el carrito</p>
          )
        }
        
    }

    return (
        <div className=" w-full max-w-sm pr-4">
      <Popover className="relative align-middle">
        {({ open }) => (
          <>
            <Popover.Button
            className="items-centerfocus:outline-none inline-flex items-center">
                <div className='flex items-center'>
                <p id="cart" className="text-brand-lightgray">Cart</p>
                <i id="cartEmpty" className='bx bx-cart text-2xl text-brand-lightgray'></i><i id="cartFull" className='bx bxs-cart hidden text-2xl text-brand-red transition duration-300 ease-in-out' ></i>
                </div>
                
                <div className="absolute top-4 left-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-8 w-8 cursor-pointer"
                  >
                    {/* Agrega aquí el contenido del icono del carrito */}
                  </svg>

                  {total_items > 0 && (
                    <div className="absolute top-0 right-0 bg-brand-red text-sm text-brand-lightgray rounded-full w-5 h-5 flex items-center justify-center">
                      {total_items}
                    </div>
                  )}
                </div>
                
                {/* <TotalItems cantidadEnCarrito={total_items} /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 md:-left-16 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform sm:px-0 lg:max-w-small drop-shadow-2xl bg-brand-lightgray rounded-lg overflow-y-auto md:max-h-[550px] xl:max-h-[600px]">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <h2 className="font-semibold pt-4 pl-2 bg-brand-lightgray border-b-[0.5px] border-brand-darkgray mx-4">Cart</h2>
                <div className="relative grid gap-6 bg-brand-lightgray p-7 lg:grid-cols-1">
                    {mapItems()}
                  </div>
                  <div className="bg-brand-lightgray py-4 border-t-[0.5px] border-brand-darkgray mx-4">
                    <p className="py-1">Total: ${amount}</p>
                    {checkoutButton()}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>   
    )
}

const mapStateToProps = state => ({
  isAutheticated: state.Auth.isAutheticated,
  items: state.Cart.items,
  amount: state.Cart.amount,
  compare_amount: state.Cart.compare_amount,
  total_items: state.Cart.total_items
})

export default connect(mapStateToProps,{
  get_items,
  get_total,
  get_item_total,
  remove_item,
  
}) (Cart);