import { Link } from "react-router-dom"
// Supongamos que tienes un array de objetos con id-imagen

function CardProduct({ id, src, name, price, compare_price }) {

    return(
        <div className="sm:mb-8 flex gap-10 justify-center">
            <Link to={`../products/${id}`} className="transition duration-300 ease-in-out hover:-translate-y-1 drop-shadow-2xl">
                <img 
                    /* src={obtenerImagenPorId(id)} */
                    src={src}
                    className="object-cover h-80 w-72 rounded-t-xl" 
                />
                <div className="bg-brand-lightgray p-3 rounded-b-xl justify-start items-center shadow-xl">
                    <div className="flex gap-1"><p>{name}</p></div>
                    {price !== compare_price ?
                    <div className="flex gap-1">
                        <p className="flex gap-1 text-sm text-brand-red">${price}</p>
                        <p className="flex gap-1 text-sm text-gray-600 line-through">${compare_price}</p>
                    </div>
                    :
                    <p className="flex text-sm text-gray-600">${price}</p>
                    }
                </div>
            </Link>
        </div>
        
    );
}

export default CardProduct;