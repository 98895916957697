import { Link, Navigate } from "react-router-dom";
import Layout from "../../hocs/layouts/Layout";
import { useState, useEffect } from "react";
import 'boxicons'
import { OneEightyRing } from "react-svg-spinners";
import isoTipo from '../../assets/img/isotipo-color.svg'
import { connect } from 'react-redux'
import { reset_password_confirm } from "../../redux/actions/auth";
import { useParams } from "react-router-dom";

function ResetPasswordConfirm({
    reset_password_confirm,
    loading,
    isAuthenticated
}) {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const params = useParams()

    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: '',

      })

    const {
        new_password,
        re_new_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e =>{
        e.preventDefault();
        const uid = params.uid
        const token = params.token

        reset_password_confirm(uid, token, new_password, re_new_password)
        if (new_password === re_new_password) {
            setRequestSent(true);
        };

      }
      
    if (requestSent && !loading)
      return <Navigate to='/' />;

    return(
        <Layout >
            <div className="flex ">
            <div className="mx-auto justify-center max-w-xl border border-brand-darkgray px-2 rounded-2xl my-48 shadow-navbar">
            <img
                src={isoTipo}
                width={70}
                height={70}
                className="mt-6 flex mx-auto"
                />
            <h2 className="mt-4 flex justify-center text-gray-900 text-4xl font-semibold">Nueva contraseña</h2>

            <form onSubmit={e => onSubmit(e)} className="p-6" method="POST">
            <div class="relative z-0 w-full mb-6 group">
                <input type="password" name="new_password" value={new_password} onChange={e=>onChange(e)} id="floating_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nueva contraseña</label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
                <input type="password" name="re_new_password" value={re_new_password} onChange={e=>onChange(e)} id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_repeat_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirmar contraseña</label>
            </div>
            
            
            { loading ? <button formMethod="POST" type="submit" class="text-white bg-brand-red hover:bg-darkred focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-full px-36 py-2.5 text-center dark:bg-black dark:hover:bg-gray-800 dark:focus:ring-gray-800">
                <OneEightyRing 
                width={30}
                height={30}
                color="#fff" 
                /></button>:
            <button formMethod="POST" type="submit" class="text-brand-lightgray bg-brand-red hover:bg-darkred focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-full px-36 py-2.5 text-center dark:bg-black dark:hover:bg-brand-darkgray dark:focus:ring-brand-darkgray transition duration-200 ease-in-out">Establecer contraseña</button>}
            </form>
            </div>
            </div>

        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
    reset_password_confirm
}) (ResetPasswordConfirm);