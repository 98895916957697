import { Fragment } from "react";
import { connect } from "react-redux";

import 'boxicons'



function Alert ({ alert }) {

    const displayAlert = () => {
        const alertColor = alert?.alertType
        const getAlertClass = () => {
            if (alertColor === "red") {
              return "bg-red-200 ";
            } else if (alertColor === "green") {
              return "bg-green-200";
            }
            return "";
            };
            
        const getIconClass = () => {
            if (alertColor === "red") {
              return "bx bxs-x-circle text-red-800 text-2xl";
            } else if (alertColor === "green") {
              return "bx bxs-check-circle text-green-800 text-2xl";
            }
            return "";
            };

        if (alert !== null){
            return (
                <div className={`rounded-md p-4 ${getAlertClass()}`}>
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                    <i className={`${getIconClass()}`} type="solid"></i>
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium">{alert?.msg}</p>
                    </div>
                </div>
                </div>
            )
        } else {
            return (
                <Fragment>
                </Fragment>)
        }
    }

    return (
        <Fragment>
            {displayAlert()}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    alert: state.Alert.alert
});

export default connect(mapStateToProps)(Alert);