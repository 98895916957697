import { Typewriter } from 'react-simple-typewriter';
import 'boxicons';
import '../../styles/index.css';
import TextWithShadow from './TextWithShadow'

function Header() {
  return (
    <header className="pt-44 lg:pt-60 pl-5 md:pl-20 header bg-cover bg-center md:bg-center h-screen">
      <div className=''>
        <h1 className="text-4xl md:text-7xl font-bold text-white ">
          <span className="border-b-4 border-brand-red">
            <TextWithShadow text='Buzo Boxy Fit' isTittle={true}/>
          </span>
        </h1>
        <h2 className="hidden md:flex mt-8 text-4xl font-light text-white">
          <span className=''></span>
          <span className='typewriter-text'>
            <Typewriter
              words={['Ya disponible.']}
              loop={0}
              cursor
              cursorColor="red"
              cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1700}
              onLoopDone={0}
              onType={0}
            />
          </span>
        </h2>
        <h2 className="mt-8 text-2xl font-light text-white block md:hidden">
          <span className='block'></span>
          <span className='block typewriter-text'>
            <Typewriter
              words={['Ya disponible.']}
              loop={0}
              cursor
              cursorColor="red"
              cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1700}
              onLoopDone={0}
              onType={0}
            />
          </span>
        </h2>
      </div>
      {/* <img src={Foto} className='object-cover h-[600px] w-[500px] absolute top-24 right-24' /> */}
    </header>
  );
}

export default Header;