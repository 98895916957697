import Layout from '../../hocs/layouts/Layout';
import { useParams } from 'react-router';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async'

function PaymentFailureOrderId() {
  const { orderId } = useParams();
  const [order, setOrder] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
          }
        };

        // Reemplaza la URL con la dirección de tu API
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/order/delete-order/${orderId}`, config);

        if (response.status === 200) {
          if (response.data.order.status === "processed"){
            // Accede a los datos de la respuesta
            setOrder(response.data.order)
          };
        };
        
      } catch (error) {
        // Maneja errores aquí, por ejemplo, imprime el mensaje de error
        console.error('Error al hacer la petición GET:', error.message);
      }
    };

    // Llama a la función asincrónica
    fetchData();
  }, [orderId]); // Asegúrate de incluir orderId en la lista de dependencias para que useEffect se ejecute cuando cambie


  return (
    <Layout>
      <Helmet>
        <title>Best Dressed | Pago Confirmado</title>
        <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
        <meta name="keyword" content="
                    Best dressed
                    Primera Cápsula
                    ya disponible
                    F*ck The Dress Code
                    Bst Drsd: Experience
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario"></meta>
        <meta name="robots" content="all"></meta>
        <link rel="canonical" href=""></link>
        <meta name="author" content="Vainfax Solutions"></meta>
        <meta name="publisher" content="Vainfax Solutions"></meta>

        {/* Social Media Tags */}
        <meta property="og:title" content='Best Dressed | Productosz' />
        <meta property="og:description" content='' />
        <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
        <meta property="og:image" content='' />

        <meta name="twitter:title" content='Best Dressed' />
        <meta
          name="twitter:description"
          content=''
            />
      </Helmet>
      <section className='m-5 mt-10 lg:m-24'>
                <h2 className='flex md:hidden justify-center text-black text-2xl lg:text-4xl pt-24 font-semibold whitespace-nowrap'>La compra N.º <b>{orderId}</b>, ha</h2>
                <h2 className='flex md:hidden justify-center text-black text-2xl lg:text-4xl font-semibold mb-6 whitespace-nowrap'>sido rechazada.</h2>
                
                <h2 className='hidden md:flex justify-center text-black text-2xl lg:text-4xl pt-24 font-semibold mb-6 whitespace-nowrap'>La compra N.º <b>{orderId}</b>, ha sido rechazada.</h2>
                <div id='div' className='lg:max-w-60 pt-6 pb-24'>
                    <p className='flex justify-center text-center text-lg pt-4 mx-2 md:mx-16'>Algo salió mal en el proceso de compra, te invitamos a volver a intentarlo!</p>
                </div>
            </section>
    </Layout>
  );
}

export default PaymentFailureOrderId;