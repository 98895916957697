import Layout from '../../hocs/layouts/Layout';
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { get_collection } from '../../redux/actions/collections'
import { get_products } from '../../redux/actions/products';

import CardProduct from "../../components/Cards/CardProduct";

function Products({
    get_collection,
    get_products, 
    products,

}){
    useEffect(() => {
        window.scrollTo(0,0)
        get_collection()
        get_products()
    }, [])

    const showProducts = () => {
          if (products && Array.isArray(products)) {
            return products.map((product, index) => (
              <div key={index}>
                <CardProduct
                  id={product.id}
                  src={product.get_photo}
                  name={product.name}
                  price={product.price}
                  compare_price={product.compare_price}
                />
              </div>
            ));
          } else {
            return <div>No products found.</div>;
          }
        };


    return (
        <Layout>
          <Helmet>
                <title>Best Dressed | Productos</title>
                <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
                <meta name="keyword" content="
                    Best dressed
                    Primera Cápsula
                    ya disponible
                    F*ck The Dress Code
                    Bst Drsd: Experience
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario"></meta>
                <meta name="robots" content="all"></meta>
                <link rel="canonical" href=""></link>
                <meta name="author" content="Vainfax Solutions"></meta>
                <meta name="publisher" content="Vainfax Solutions"></meta>

                {/* Social Media Tags */}
                <meta property="og:title" content='Best Dressed | Productosz' />
                <meta property="og:description" content='' />
                <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
                <meta property="og:image" content='' />

                <meta name="twitter:title" content='Best Dressed' />
                <meta
                    name="twitter:description"
                    content=''
                />
                
            </Helmet>
            <div className="bg-brand-lightgray xl:pt-16 m:12 pt-12">
                
                    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="relative z-10 flex items-baseline justify-between pt-20 pb-3 md:pb-6 border-b border-brand-darkgray">
                        <h2 className="text-4xl font-extrabold tracking-tight text-black">Productos</h2>
                    </div>

                    <section aria-labelledby="products-heading" className="pt-6 pb-24">
                        <h2 id="products-heading" className="sr-only">
                        Products
                        </h2>
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                          {/* Product grid */}
                          <div className="lg:col-span-4 flex flex-wrap gap-4 justify-center">
                              
                              {products && showProducts()}

                          </div>
                        </div>
                    </section>
                    </main>
                </div>
        </Layout>
    );
}

const mapStateToProps = state => ({
    collections: state.Collections.collections,
    products: state.Products.products,

})

export default connect(mapStateToProps,{
    get_collection,
    get_products,
}) (Products)


