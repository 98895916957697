import React from 'react';

const MovingText = () => {
  return (
    <div className="w-full overflow-hidden bg-brand-red">
      <div className="animate-marquee-container text-black text-lg whitespace-nowrap top-1 flex gap-6 py-1 sm:gap-3 sm:text-base font-semibold">
        <p className='animate-marquee'>Envíos a todo el país (GRATIS en Rosario)</p>
        <p className='animate-marquee'>-</p>
        <p className='animate-marquee'>3 cuotas SIN INTERÉS</p>
        <p className='animate-marquee'>-</p>
        <p className='animate-marquee'>20% OFF con transferencia</p>
      </div>
    </div>
  );
};

export default MovingText;