import React, { useState } from 'react';

function ImageSelector({ images }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <div className='md:flex'>
      <div className="hidden md:block image-list">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Imagen ${index + 1}`}
            className={`${selectedImage === imageUrl ? 'selected' : ''} h-24 w-20 object-cover rounded-md mb-4`}
            onClick={() => handleImageClick(imageUrl)}
          />
        ))}
      </div>
      {selectedImage && (
        <div className='sm:flex sm:justify-center px-4'>
          <img src={selectedImage} alt="Imagen seleccionada" className='h-[25rem] w-[20rem] md:h-[40rem] md:w-[30rem] object-cover rounded-lg' />
        </div>
      )}
      <div className="md:hidden flex image-list justify-center gap-4 md:gap-4 mt-4">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Imagen ${index + 1}`}
            className={`${selectedImage === imageUrl ? 'selected' : ''} h-20 w-16 md:h-24 md:w-20 object-cover rounded-md mb-4`}
            onClick={() => handleImageClick(imageUrl)}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageSelector;