import Layout from '../../hocs/layouts/Layout';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import axios from 'axios'; 
import { Link } from 'react-router-dom';

function Orders(){

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
              }
            };
    
            // Reemplaza la URL con la dirección de tu API
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/order/get-orders`, config);
    
            if (response.status === 200) {
                setOrders(response.data.orders)
            };
            
          } catch (error) {
            // Maneja errores aquí, por ejemplo, imprime el mensaje de error
            console.error('Error al hacer la petición GET:', error.message);
          }
        };
    
        // Llama a la función asincrónica
        fetchData();
      }, []);

    const showOrders = () => {
        const statusOrder = (order) => {
            if (order.status === "not_processed") {
                return(<p>Estado: Falta de pago</p>)
            } else if (order.status === "processed") {
                return(<p>Estado: Pedido confirmado</p>)
            } else if (order.status === "shipped") {
                return(<p>Estado: En camino</p>)
            } else if (order.status === "delivered") {
                return (<p>Estado: Entregado</p>)
            } else if (order.status === "cancelled") {
                return (<p>Estado: Cancelado</p>)
            }
        }
        if (orders) {
            return(
                orders.map((order, index) => (
                <Link to={order.status==="processed"?`/payment/success/${order.id}`:`/payment/pending/${order.id}`}>
                    <article className='bg-brand-lightgray hover:bg-brand-darkgray mb-3 md:flex gap-6 p-6 border border-brand-lightgray hover:border-brand-darkgray rounded-lg max-w-5xl justify-between mx-auto drop-shadow-2xl transition ease-in-out duration-150'>
                        <p className='flex'>
                            Order N°: {order.id}
                        </p>
                        <p className='flex'>
                            {statusOrder(order)}
                        </p>
                        <p className='flex'>
                            Monto: {order.amount}
                        </p>
                        <p className='flex'>
                            Envío: {order.shipping_price}
                        </p>
                        <p className='flex'>
                            Cuidad: {order.city}
                        </p>
                        <p className='flex'>
                            Direccion: {order.address_street + ' ' + order.address_number}
                        </p>
                    </article>
                </Link>
                ))
            )
        }
    };
    return (
        <Layout>
            <Helmet>
            <title>Best Dressed | Ordenes de compra</title>
                <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
                <meta name="keyword" content="
                    Best dressed
                    Primera Cápsula
                    ya disponible
                    F*ck The Dress Code
                    Bst Drsd: Experience
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario"></meta>
                <meta name="robots" content="all"></meta>
                <link rel="canonical" href=""></link>
                <meta name="author" content="Vainfax Solutions"></meta>
                <meta name="publisher" content="Vainfax Solutions"></meta>

                {/* Social Media Tags */}
                <meta property="og:title" content='Best Dressed | Productosz' />
                <meta property="og:description" content='' />
                <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
                <meta property="og:image" content='' />

                <meta name="twitter:title" content='Best Dressed' />
                <meta
                    name="twitter:description"
                    content=''
                />
            </Helmet>

            <div className="bg-brand-lightgray xl:pt-16 m:12 pt-12">
                
                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative z-10 flex items-baseline justify-between pt-20 pb-3 md:pb-6 border-b border-brand-darkgray">
                    <h2 className="text-4xl font-extrabold tracking-tight text-black">Mis Órdenes</h2>
                </div>

                <section aria-labelledby="products-heading" className="pt-6 pb-24">
                    <h2 id="products-heading" className="sr-only">
                        Mis Órdenes
                    </h2>
                    {/* Product grid */}
                    <div className="">
                        {showOrders()}
                    </div>
                </section>
                </main>
            </div>
        </Layout>
    );
}


export default Orders