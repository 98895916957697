import { Link, Navigate } from "react-router-dom";
import Layout from "../../hocs/layouts/Layout";
import { useState, useEffect } from "react";
import 'boxicons'
import { OneEightyRing } from "react-svg-spinners";
import isoTipo from '../../assets/img/isotipo-color.svg'
import { Helmet } from "react-helmet-async";
import { connect } from 'react-redux'
import { login } from "../../redux/actions/auth";

function Login({
    login,
    loading,
    isAuthenticated
}) {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [formData, setFormData] = useState({
        email: '',
        password: '',

      })

    const {
        email,
        password,
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e =>{
        e.preventDefault();
        login(email, password);
      }
      
    if (isAuthenticated && !loading)
      return <Navigate to='/' />;

    return(
        <Layout >
            <Helmet>
                <title>Best Dressed | Iniciar Sesión</title>
                <meta name="description" content="Descubre la esencia única de la moda urbana con Best Dressed, una firma argentina nacida en Rosario y eleva tu estilo con prendas de calidad que reflejan estatus y autenticidad en el vibrante escenario de la moda urbana."></meta>
                <meta name="keyword" content="
                    Ropa urbana argentina
                    Moda urbana de calidad
                    Marca de status en moda urbana
                    Streetwear argentino
                    Diseño de autor urbano
                    Moda urbana auténtica
                    Prendas de calidad argentina
                    Elegancia en street fashion
                    Marca de ropa desde Santa Fe
                    Estilo urbano de Rosario
                    Ropa exclusiva de Argentina
                    Moda contemporánea argentina
                    Streetwear de autor
                    Tendencias urbanas en Argentina
                    Ropa de calidad para la ciudad"></meta>
                <meta name="robots" content="all"></meta>
                <link rel="canonical" href=""></link>
                <meta name="author" content="Vainfax Solutions"></meta>
                <meta name="publisher" content="Vainfax Solutions"></meta>

                {/* Social Media Tags */}
                <meta property="og:title" content='Best Dressed | Productosz' />
                <meta property="og:description" content='' />
                <meta property="og:url" content="https://www.bestdressedclo.com.ar" />
                <meta property="og:image" content='' />

                <meta name="twitter:title" content='Best Dressed' />
                <meta
                    name="twitter:description"
                    content=''
                />
            </Helmet>
            <div className="flex ">
            <div className="mx-auto justify-center max-w-xl border border-brand-darkgray rounded-2xl my-48 shadow-navbar">
            <img
                src={isoTipo}
                width={70}
                height={70}
                className="mt-6 flex mx-auto"
                />
            <h2 className="mt-4 flex justify-center text-gray-900 text-4xl font-semibold">Inicía sesión</h2>

            <form onSubmit={e => onSubmit(e)} className="p-6" method="POST">
            <div class="relative z-0 w-full mb-6 group">
                <input type="email" name="email" value={email} onChange={e=>onChange(e)} id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Correo electronico</label>
            </div>
            <div class="relative z-0 w-full mb-3 group">
                <input type="password" name="password" value={password} onChange={e=>onChange(e)} id="floating_password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-brand-red focus:outline-none focus:ring-0 focus:border-brand-red peer" placeholder=" " required />
                <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-red peer-focus:dark:text-brand-red peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contraseña</label>
            <p className="pt-4 aling-center"><Link to="/reset_password" className="text-blue-500 text-sm">¿Olvidaste tu contraseña?</Link></p>
            </div>
            { loading ? <button formMethod="POST" type="submit" class="text-brand-lightgray bg-brand-red hover:bg-darkred focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-full px-36 py-2.5 text-center dark:bg-black dark:hover:bg-brand-lightgray dark:focus:ring-brand-lightgray">
                <OneEightyRing 
                width={30}
                height={30}
                color="#fff" 
                /></button>:
            <button formMethod="POST" type="submit" class="text-brand-lightgray bg-brand-red hover:bg-darkred focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-full md:px-36 py-2.5 text-center dark:bg-black dark:hover:bg-brand-darkgray dark:focus:ring-brand-darkgray transition duration-200 ease-in-out">Inicía sesión</button>}
            
            <p className="pt-4">¿No tienes una cuenta? <Link to="/signup" className="text-blue-500">Registrate</Link></p>
            </form>
            </div>
            </div>

        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
    login
}) (Login);