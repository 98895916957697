import {
    GET_COLLECTION_SUCCESS,
    GET_COLLECTION_FAIL,
} from '../actions/types'

const initialState = {
    collections: null
}

export default function Collections(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_COLLECTION_SUCCESS:
            return {
                ...state,
                collections: payload.collections
            }
        case GET_COLLECTION_FAIL:
            return {
                ...state,
                collections: null
            }

        default:
            return state
    }
}