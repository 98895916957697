import Logo_1 from '../../assets/img/logotipo-2-color.svg';
import Logo_2 from '../../assets/img/logotipo-1-color.svg';
import { Link, NavLink, Navigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import Cart from '../buttons/Cart';
import Alert from '../alert'
import { connect } from 'react-redux';
import { Menu , Transition } from '@headlessui/react'
import { logout } from '../../redux/actions/auth'
import './style.css'
import MovingText from '../movingText';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Navbar ({
  isAuthenticated,
  user,
  logout
}){

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  //const [redirect, setRedirect] = useState(false);

  const logoutHandler = () => {
    logout()
    //setRedirect(true);
    return (<Navigate to="/" />)
  };
  
  //if (redirect)
  

  const authLinks = (
    <Menu as="div" className="relative inline-block text-left mx-auto sm:m-auto mr-10">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-full  text-sm font-medium text-gray-700 focus:outline-none hover:ring-2 hover:ring-brand-red transition ease-in-out duration-150">
          <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      > 
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg drop-shadow-2xl bg-brand-lightgray ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {/* <Menu.Item>
              {({ active }) => (
                <Link
                  to="/dashboard"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Dashboard
                </Link>
              )}
            </Menu.Item> */}
            
            
            <form>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/orders"
                    className={classNames(
                      active ? 'bg-brand-darkgray text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm'
                    )}
                  >
                    Mis Ordenes
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logoutHandler}
                    className={classNames(
                      active ? 'bg-brand-darkgray text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm'
                    )}
                  >
                    Cerrar sesión
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )


    window.onscroll = function() {scrollFunction()}

    function scrollFunction() {
        if(document.getElementById('navbar')){
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                document.getElementById('navbar').classList.add('shadow-navbar');
                document.getElementById('navbar').classList.add('bg-brand-lightgray');
                document.getElementById('cart').classList.remove('text-brand-lightgray');
                document.getElementById('cart').classList.add('text-black');
                document.getElementById('cartEmpty').classList.remove('text-brand-lightgray');
                document.getElementById('cartEmpty').classList.add('text-black');
                if(document.getElementById('bx-x')){
                  document.getElementById('bx-x').classList.remove('text-brand-lightgray');
                  document.getElementById('bx-x').classList.add('text-black');
                }
                if(document.getElementById('bx-menu')){
                  document.getElementById('bx-menu').classList.remove('text-brand-lightgray');
                  document.getElementById('bx-menu').classList.add('text-black');
                }
                
            }else{
                document.getElementById('navbar').classList.remove('shadow-navbar');
                document.getElementById('navbar').classList.remove('bg-brand-lightgray');
                document.getElementById('cart').classList.remove('text-black');
                document.getElementById('cart').classList.add('text-brand-lightgray');
                document.getElementById('cartEmpty').classList.remove('text-black');
                document.getElementById('cartEmpty').classList.add('text-brand-lightgray');
                if(document.getElementById('bx-x')){
                  document.getElementById('bx-x').classList.remove('text-black');
                  document.getElementById('bx-x').classList.add('text-brand-lightgray');
                }
                if(document.getElementById('bx-menu')){
                  document.getElementById('bx-menu').classList.remove('text-black');
                  document.getElementById('bx-menu').classList.add('text-brand-lightgray');
                }
            }
        }
      }

  return(
    <>
    <nav id="navbar" className="w-full top-0 transition duration-300 ease-in-out z-40 fixed">
      <MovingText />
      <div className='justify-between flex px-10 py-6 sm:px-5 sm:py-4'>
        <section className="inline-flex gap-10 justify-center">
          <Link to="/">
            <img
              src={Logo_1}
              width={300}
              height={100}
              className="logo_1"
              />
            <img
              src={Logo_2}
              width={175}
              height={100}
              className="logo_2"
              />
          
          </Link>
          <NavLink to="/" className="hidden md:inline-flex items-center text-m text-black" >Home</NavLink>
          <NavLink to="/products" className="hidden md:inline-flex items-center text-m text-black" >Productos</NavLink>      
        </section>

        <section  className="inline-flex">
              <div className='md:hidden mt-1'>
                <button onClick={toggleMenu} id='menu-toggle'>
                  {isOpen ? <i id="bx-x" class='bx bx-x text-brand-lightgray text-4xl'></i>:<i id="bx-menu" class='bx bx-menu text-brand-lightgray text-4xl'></i>}
                </button>
              </div>
              
              <div id="menu" className="hidden md:inline-flex gap-5 justify-center text-m text-black items-center">
              <span className='lg:inline-flex'>
                  <Cart />
                </span>
                {isAuthenticated ? authLinks:
                <Link to="/login" className="item-menu inline-flex text-black border-2 rounded-lg border-brand-darkgray py-1.5 px-4 bg-brand-darkgray transition duration-300 ease-in-out">Ingresar</Link>}
                {isAuthenticated ? <span></span>: <Link to="/signup" className="item-menu inline-flex text-brand-lightgray border-2 rounded-lg border-brand-red bg-brand-red py-1.5 px-4 transition duration-300 ease-in-out">Registrarse</Link>}
              </div>

        </section>
      </div>
      
      <Alert />

      {isOpen ? 
            <div className='h-screen bg-brand-lightgray'>
              <div className='pt-10 flex flex-col'>
                <span className='items-end text-xl text-black mx-auto pl-6'>
                  <Cart />
                </span>
                <NavLink to="/" className="items-end text-xl text-black mx-auto pt-6">Home</NavLink>
                <NavLink to="/products" className="items-end text-xl text-black mx-auto pt-6 ">Productos</NavLink>
                {isAuthenticated ?
                  <div className='mx-auto pt-6'>
                    <div>
                    <Link
                    to="/orders"
                    className='text-xl pt-6 pb-10 text-black'
                    >
                    Mis Órdenes
                    </Link>
                    </div>
                    <div>
                    <button
                      onClick={logoutHandler}
                      className={"text-xl pt-6 pb-10 text-black"}
                    >
                      Cerrar sesión
                    </button>
                    </div>
                  </div>:
                <></>}
              </div>

              <div className='flex gap-x-4 justify-center pt-10'>
              {isAuthenticated ?
                  <></>:
                <Link to="/login" className="inline-block text-black border-2 rounded-lg border-brand-darkgray py-1.5 px-4 bg-brand-darkgray transition duration-300 ease-in-out">Ingresar</Link>}
                {isAuthenticated ? <span></span>:
                <Link to="/signup" className="inline-block text-brand-lightgray border-2 rounded-lg border-brand-red bg-brand-red py-1.5 px-4 transition duration-300 ease-in-out">Registrarse</Link>}
              </div>
            </div> 
            :
            <></>}
    </nav>
    </>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user
})


export default connect(mapStateToProps, {
  logout
})(Navbar)


/* <Link to="/account" className="inline-flex text-black border-b-2 border-white hover:border-brand-red transition duration-300 ease-in-out">Cuenta</Link> */

