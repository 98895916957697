import { connect } from 'react-redux';
import logo from '../../assets/img/isotipo-color.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react'
import { newsletter } from '../../redux/actions/newsletter';
import X_svg from '../../assets/img/x-gris1.svg'

const navigation = {
    support: [
      { name: 'Email', href: 'mailto:bestdressedclothingtm@gmail.com' },
    ],
    social: [
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/bestdressedclo/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'X',
        href: 'https://twitter.com/BestDressedclo',
        icon: (props) => (
          <img src={X_svg} className='ml-2 h-5 w-5'></img>
        ),
      },
      {
        name: 'TikTok',
        href: 'https://tiktok.com/@bestdressed.clo',
        icon: (props) => (
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 32 32 "{...props}>
            <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z"/>
          </svg>
        ),
      },
    ],
  }

function Footer(){

    const [formData, setFormData] = useState({
      	email: '',
    })

	const { 
        email,
    } = formData;

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e, email) =>{
        e.preventDefault();
        newsletter(email);
      }

    return(
        <footer className="bg-black" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="md:ml-20 space-y-8 xl:col-span-1">
              <img
                src={logo}
                width={75}
                height={75}
                className=""
                />
              <p className='text-brand-lightgray'>Best Dressed® es una marca de ropa fundada en Rosario, Argentina, en el año 2024.</p>
              
            </div>
            <div className="mt-12 md:grid md:grid-cols-2 md:gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-1 md:gap-8">
               <form onSubmit={e => onSubmit(e)} className='md:pr-24'>
                  <h1 className='flex justify-center text-brand-lightgray text-xl font-semibold mb-8'>Newsletter</h1>
                  <div className="relative z-0 w-full mb-6 group flex justify-center mt-4">
                    <input type="email" name="email" value={email} onChange={e=>onChange(e)}  id="floating_email" class="block py-2.5 px-0 w-full text-sm text-brand-lightgray bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-brand-lightgray dark:border-brand-lightgray dark:focus:border-brand-lightgray focus:outline-none focus:ring-0 focus:border-brand-lightgray peer" placeholder=" " required />
                    <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-brand-lightgray dark:text-brand-lightgray duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-brand-lightgray peer-focus:dark:text-brand-lightgray peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 left-0">Correo electronico</label>
                    <button formMethod="POST" type="submit" className="flex justify-center text-brand-lightgray bg-black border-2 border-brand-lightgray px-4 py-2 rounded-lg ml-6">
                      Subscribirse
                    </button>
                  </div>
                  
               </form>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-semibold text-brand-lightgray">Contactanos</h3>
                  <ul role="list" className="mt-4 space-y-4 mr-16">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-brand-lightgray hover:text-brand-darkgray transition duration-300 ease-in-out">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-semibold text-brand-lightgray">Nuestras Redes</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.social.map((item) => (
                      <li key={item.name} className="flex">
                        <a href={item.href} className="text-base border-b-2 border-transparent hover:border-brand-red transition duration-300 ease-in-out mr-50 flin-flex text-brand-lightgray hover:text-brand-darkgray">
                          {item.name} 
                        </a>
                        <item.icon className="in-flex ml-1 h-6 w-6 text-brand-lightgray" aria-hidden="true" />
                      </li>
                    ))}
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-brand-lightgray pt-8">
            <p className="text-base text-brand-lightgray xl:text-center">2024 &copy; Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps,{

}) (Footer);